// 统计报表表头
export const statisticsColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '登记时间',
      align: 'center',
      children: [
        {
          title: '日期',
          align: 'center',
          key: 'day',
          width: 100,
          customRender: (text, record) => {
            let fromTime = record.fromTime
            if (fromTime) {
              if (fromTime.slice(8, 9) === '0') {
                return fromTime.slice(9, 10)
              } else {
                return fromTime.slice(8, 10)
              }
            }
          }
        },
        {
          title: '时分',
          align: 'center',
          key: 'hour_minute',
          width: 100,
          customRender: (text, record) => {
            return `${record.fromTime.slice(11, 16)}`
          }
        }
      ]
    },
    {
      title: '来电人员',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'fromName',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'fromPhone',
          width: 120
        }
      ]
    },
    /*{
      title: '值班人员',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'officer',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'officePhone',
          width: 120
        }
      ]
    },*/
    {
      title: '任务登记原因',
      align: 'center',
      width: 300,
      ellipsis: true,
      scopedSlots: { customRender: 'reason' }
    },
    {
      title: '办理结果',
      align: 'center',
      width: 150,
      ellipsis: true,
      dataIndex: 'feedbackResult'
    },
    {
      title: '备注',
      align: 'center',
      width: 150
    }
  ]
}